import * as React from "react";
import { css } from "@emotion/react";
import { moreThanBreakpoint } from "../../styles/base/responsiveStyle";
import { space } from "../../styles/base/spaceStyle";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  styles?: any;
}

export const SearchBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  return <div css={blockStyle}>{props.children}</div>;
};

const blockStyle = css`
  padding: ${space.atom} 0 ${space.atom2x};
  box-sizing: border-box;
  ${moreThanBreakpoint("tablet")} {
    padding: ${space.atom} 0 ${space.atom2x};
    min-height: calc(100vh - 1219px);
  }
`;
