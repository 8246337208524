import * as React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import {
  backgroundColor,
  shadeColor,
} from "main/javascripts/styles/base/colorStyle";

interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  styleKey?: keyof IStyle;
  backgroundStyleKey?: keyof IBackgroundStyle;
}

const BasicBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const {
    children,
    styleKey = "block",
    backgroundStyleKey = "primary",
  } = props;
  return (
    <div css={[blockStyle[styleKey], backgroundStyle[backgroundStyleKey]]}>
      {children}
    </div>
  );
};
export const BasicBlock: any = React.memo(BasicBlockComponent);

export interface IStyle {
  block: any;
  blockWide: any;
  blockFullWidth: any;
  blockMultiCol: any;
}

export interface IBackgroundStyle {
  primary: any;
  secondary: any;
  secondary2: any;
}

const blockStyle: IStyle = {
  block: css`
    padding: ${space.block2x} ${space.block};
    max-width: ${maxWidth.page1col};
    margin: 0 auto;
    box-sizing: border-box;
  `,
  blockWide: css`
    padding: ${space.block2x} ${space.block};
    max-width: ${maxWidth.page1colWide};
    margin: 0 auto;
    box-sizing: border-box;
  `,
  blockFullWidth: css`
    padding: ${space.block2x} 0;
    max-width: 100%;
    box-sizing: border-box;
  `,
  blockMultiCol: css`
    ${moreThanBreakpoint("desktop")} {
      padding: ${space.block2x} ${space.block};
      margin: 0 auto;
      max-width: ${maxWidth.page2col};
      box-sizing: border-box;
    }
  `,
};

const backgroundStyle: IBackgroundStyle = {
  primary: css``,
  secondary: css`
    background-color: ${backgroundColor.secondaryLightColor};
  `,
  secondary2: css`
    background-color: ${shadeColor(backgroundColor.secondaryLightColor, -2)};
  `,
};
